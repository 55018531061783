import React, { useContext } from 'react'
import styled from 'styled-components'
import { borders, breakpoints, colors, layout } from '../../theme'
import Navbar from '../../components/Navbar'
import SalesPictureTitle from '../../components/SalesPictureTitle'
import { useRedirect } from '../../hooks/useRedirect'
import { navigate } from '../../components/Link'
import { Button, StyledButton, Pulse } from '@monbanquet/crumble'
import { EventContext } from '../../components/context/EventContext'
import { defaultBanquetDurations } from '../../components/context/EventContextDefaults'
import CheckboxTile, { StyledCheckboxTile } from '../../components/CheckboxTile'
import ButtonGroup from '../../components/ButtonGroup'
import { useWithDelay } from '../../hooks/useWithDelay'

const DurationPage = ({ location: { state } }) => {
  const redirect = useRedirect(!state || !state.next, '/demande/format')

  const {
    state: { banquetDuration, category, multiMoment },
    dispatch: dispatchEventCtx,
    categories,
  } = useContext(EventContext)

  const submit = () => navigate('/demande/convives', { state: { next: true } })
  const [submitWithDelay, isDelayed] = useWithDelay(submit, 400)

  return (
    <StyledDurationPage>
      <Navbar />
      {redirect ? null : (
        <div className="page-content">
          <SalesPictureTitle
            title={
              multiMoment
                ? `Merci de préciser la durée de votre événément.`
                : `Merci de préciser la durée de votre ${categories
                    .find(c => c.value == category)
                    .label.toLowerCase()}.`
            }
          />
          <form
            onSubmit={e => {
              e.preventDefault()
              submitWithDelay()
            }}
          >
            <fieldset className="banquet-durations">
              <ButtonGroup>
                {defaultBanquetDurations.map(q => (
                  <CheckboxTile
                    key={q.value}
                    type="radio"
                    name="banquetDuration"
                    value={q.value}
                    label={q.label}
                    checked={banquetDuration === q.value}
                    className={banquetDuration === q.value ? 'selected' : ''}
                    onChange={e =>
                      dispatchEventCtx({
                        type: 'UPDATE_BANQUET_DURATION',
                        payload: { banquetDuration: e.target.value },
                      })
                    }
                    onClick={submitWithDelay}
                  />
                ))}
              </ButtonGroup>
            </fieldset>

            <div className="btn-bar">
              <Button id="previous" onClick={() => navigate(-1)}>
                Précédent
              </Button>
              <Button id="next" type="submit">
                {isDelayed ? <Pulse /> : <span>Suivant &rarr;</span>}
              </Button>
            </div>
          </form>
        </div>
      )}
    </StyledDurationPage>
  )
}

const StyledDurationPage = styled.div`
  .page-content {
    margin: ${layout.navbarHeight.normal}px auto;
    max-width: 550px;
    width: ${layout.width};
    text-align: center;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .page-content {
      margin-top: ${layout.navbarHeight.mobile}px;
    }
  }

  form {
    text-align: left;
    margin: 0 auto;
    margin-top: 50px;
  }

  fieldset {
    border: none;
    margin-bottom: 30px;
  }

  ${StyledCheckboxTile} {
      height: 130px;
      label {
        font-size: 1.1rem;
      }
    }
  }

  .btn-bar {
    display: flex;
    margin: 30px 0;

    & > *:first-child {
      margin-right: auto;
      margin-left: 0;
      background: transparent;
      border: 1px solid ${borders.color.light};
      color: ${colors.text.light};

      &:hover {
        box-shadow: 0 2px 4px 0 rgba(126, 126, 126, 0.11);
      }
    }

    ${StyledButton} {
      height: 38px;
      border-radius: 3px;
    }
  }
`

export default DurationPage
export { StyledDurationPage }
